/* body {
  margin: 0;
  font-family: Fieldwork, Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
/* custom colors */
// $darkBlue: #01053e;
// $blue: #04bde6;

/* change the colors */
// $primary: #0d1c74;
// $danger: #dd0000;
// $success: lime;
// $warning: orange;
// $info: aqua;

// $body-bg: #f3f3f0;

// @import "~material-design-icons/iconfont/material-icons.css";
// @import "bootstrap";

// body {
//   margin: 0;
//   font-family: "Proxima Nova", Montserrat, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   // background-color: $darkBlue;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  // box-sizing: border-box;

  width: 100%;
  height: 40px;

  // padding: 10px 12px;
  // padding: 10px;

  // border: 2px solid $blue;
  // border-radius: 4px;
  // background-color: white;

  // box-shadow: 0 1px 3px 0 #e6ebf1;
  // -webkit-transition: box-shadow 150ms ease;
  // transition: box-shadow 150ms ease;
}

// .StripeElement--focus {
//   box-shadow: 0 1px 3px 0 #cfd7df;
// }

// .StripeElement--invalid {
//   border-color: #fa755a;
// }

// .StripeElement--webkit-autofill {
//   background-color: #fefde5 !important;
// }
